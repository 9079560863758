import React from "react";
import styled from "@emotion/styled";
import Invention from "./Invention";
import { spacing, screen, fontSize } from "../../../../styles/mixins";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";
const Container = styled.div`
	z-index: 4;
	position: relative;

	background: ${({ theme }) => theme.cardBackground};

	color: ${({ theme }) => theme.cardForeground};

	${spacing("marginBottom", "xl")};

	${spacing("marginLeft", "m")};
	${spacing("marginRight", "m")};
	border: 1px solid ${({ theme }) => theme.cardForeground};
	border-top: 0;
	@media ${screen("ml")} {
		border-top: 1px solid ${({ theme }) => theme.cardForeground};
		border-radius: 30px;
		width: 50%;
		right: 40px;
		${spacing("marginTop", "xxxl")};
		margin-left: 0;
		margin-right: 0;
	}
`;

const Title = styled.h4`
	${spacing("paddingTop", "s")};
	${spacing("paddingBottom", "s")};
	${abcMaxiPlusMono};
	line-height: 1;
	${fontSize(0.5)};
	padding-left: 20px;
	border-bottom: 1px solid ${({ theme }) => theme.cardForeground};
`;

const Table = styled.table`
	border-style: hidden;
	td {
		border: 1px solid ${({ theme }) => theme.cardForeground};
	}
`;

const Inventions = ({ revolution: { inventions } }) => {
	return (
		<Container>
			<Title>NOTABLE INVENTIONS</Title>
			<Table>
				<tbody>
					{inventions.map((invention, i) => {
						return <Invention invention={invention} key={i} />;
					})}
				</tbody>
			</Table>
		</Container>
	);
};

export default Inventions;
