import React from "react";
import styled from "@emotion/styled";
import { container, spacing, screen } from "../../../../styles/mixins";
const Container = styled.div`
	${({ theme: { alignment } }) => container({ cols: 46 })};
	${spacing("marginTop", "xl")};
	${spacing("marginBottom", "m")};

	@media ${screen("md")} {
		grid-row-gap: 60px;
	}
`;

//grid-column: 25/span 21;
const Heading = styled.h4`
	grid-column: 3 / -3;
	margin-bottom: 10px;

	@media ${screen("md")} {
		margin-bottom: 0;
		grid-column: 25 / span 6;
	}

	@media ${screen("ml")} {
		grid-column: 25 / span 6;
	}

	@media ${screen("mx")} {
		grid-column: 25 / span 6;
	}

	font-feature-settings: "salt" on;
	font-weight: 600;
`;
const Content = styled.div`
	grid-column: 3 / -3;
	margin-bottom: 30px;

	@media ${screen("md")} {
		margin-bottom: 0;
		grid-column: 32 / -3;
	}

	@media ${screen("ml")} {
		grid-column: 32 / -3;
	}

	@media ${screen("mx")} {
		grid-column: 32 / -3;
	}
	text-align: justify;
	hyphens: auto;
`;

const CompanyDetails = ({ company }) => {
	return (
		<Container>
			{company.boom && <Heading>Booming Date</Heading>}
			{company.boom && <Content>{company.boom}</Content>}
			{company.mainActivity && <Heading>Main activity</Heading>}
			{company.mainActivity && <Content>{company.mainActivity}</Content>}
			{company.socialImpact && <Heading>Social Impact</Heading>}
			{company.socialImpact && <Content>{company.socialImpact}</Content>}
		</Container>
	);
};

export default CompanyDetails;
