import Tile from "./Tile";
import React from "react";
import { AnimatePresence } from "framer-motion";
import { useZoom } from "./ZoomState";
import Modal from "./Modal";
import { observer } from "mobx-react-lite";
import { ThemeProvider } from "@emotion/react";

const CardContainer = observer(({ cardContent }) => {
	const zoomState = useZoom();
	return (
		<ThemeProvider
			theme={{
				alignment: "modal",
				foreground: cardContent.foreground,
				background: cardContent.background,
				columns: 46,
			}}>
			<Tile section={cardContent} />
			<AnimatePresence>
				{zoomState.activeCard ? (
					<Modal
						section={zoomState.activeCardContent}
						key={zoomState.activeCard}
					/>
				) : null}
			</AnimatePresence>
		</ThemeProvider>
	);
});

export default CardContainer;
