import React from "react";
import styled from "@emotion/styled";
import { container, spacing, screen } from "../../../styles/mixins";
import { richText } from "../../../styles/typography-act-2";
import { ZoomProvider } from "./TextWithCard/ZoomState";
import CardContainer from "./TextWithCard/CardContainer";

const Container = styled.div`
	${({ theme: { alignment } }) =>
		container({ cols: alignment === "modal" ? 36 : 48 })};
	${({ theme: { alignment } }) =>
		spacing("marginTop", alignment === "modal" ? "xl" : "xxxl")};
	${({ theme: { alignment } }) =>
		spacing("marginBottom", alignment === "modal" ? "xl" : "xxxl")};
	position: relative;
	z-index: 3;
`;

const Content = styled.div`
	grid-row: 2;
	grid-column: 3 / -3;
	margin-top: 20px;

	@media ${screen("md")} {
		grid-column: 9 / span 32;
	}

	@media ${screen("ml")} {
		grid-column: 14 / span 22;
	}
	@media screen and (min-width: 1250px) {
		margin-top: 0;
		grid-column: 7 / 29;
		grid-row: 1;
	}

	text-align: justify;
	hyphens: auto;
	font-size: 20px;
	line-height: 1.6;
	background: ${({ theme }) => theme.background}D7;
	padding: 20px;
	border-radius: 20px;
	border: 1px solid ${({ theme }) => theme.foreground}28;
	//backdrop-filter: blur(10px);
	will-change: transform;
	${richText};
`;

const TextWithCard = ({ content, cardContent }) => {
	return (
		<Container>
			<Content dangerouslySetInnerHTML={{ __html: content }} />
			<ZoomProvider>
				<CardContainer cardContent={cardContent} />
			</ZoomProvider>
		</Container>
	);
};
export default TextWithCard;
