import React from "react";
import styled from "@emotion/styled";
import { fontSize, screen, spacing } from "../../../../styles/mixins";
import { motion } from "framer-motion";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";

const Container = styled.div`
	z-index: 3;
	grid-column: 3 / 35;
	grid-row: 1 / -1;
	display: flex;
	${spacing("marginLeft", "m")};
	${spacing("marginRight", "m")};

	@media ${screen("ml")} {
		position: sticky;
		height: 100vh;
		top: 0;
		left: 40px;
		${spacing("marginBottom", "xxl")};
		margin-left: 0;
		margin-right: 0;
	}
`;
const Inner = styled(motion.div)`
	//padding: 6% 8% 6% 8%;
	position: relative;
	width: 100%;
	background: #eeecfd;
	display: grid;
	grid-template-columns: 54px 1fr;
	grid-template-rows: auto 1fr auto auto;
	grid-column-gap: 26px;

	color: #08043c;
	margin: auto;
	&:before {
		content: "";
		position: absolute;
		left: 54px;
		top: 0;
		height: 100%;
		border-left: 1px solid #08043c;
	}
	border: 1px solid #08043c;
	border-bottom: 0;

	@media ${screen("ml")} {
		border-radius: 30px;
		border-bottom: 1px solid #08043c;
		min-height: 70vh;
		//padding: 6% calc(13.888% + 40px) 6% 8%;
	}
`;
const Number = styled.div`
	${abcMaxiPlusMono};
	line-height: 1;
	font-weight: 400;
	${fontSize(-1)};
	color: #08043c;
	grid-row: 1;
	grid-column: 2;
	${spacing("marginTop", "s")};
`;
const Title = styled.h3`
	${abcMaxiPlusMono};
	font-weight: 400;
	${fontSize(3.8)};
	color: #08043c;
	line-height: 0.94;
	padding-left: 80px;
	${spacing("marginTop", "l")};
	${spacing("paddingBottom", "s")};

	grid-row: 2;
	grid-column: 1 / -1;
`;

const Quotes = styled.div`
	grid-row: 4;
	grid-column: 2;
	${abcMaxiPlusMono};
	${fontSize(-1)};
	${spacing("marginBottom", "s")};
	line-height: 1;
`;

const Years = styled.div`
	grid-row: 1/3;
	grid-column: 1;
	writing-mode: vertical-lr;
	transform-origin: center center;
	transform: rotate(180deg);
	${abcMaxiPlusMono};
	${fontSize(-1)};
	line-height: 1;
	align-self: start;
	justify-self: center;
	${spacing("marginTop", "s")};
	font-weight: 400;
`;

const InventionsNumber = styled.div`
	writing-mode: vertical-lr;
	transform-origin: center center;
	transform: rotate(180deg);
	${abcMaxiPlusMono};
	${fontSize(-1)};
	line-height: 1;
	align-self: end;
	justify-self: center;
	${spacing("marginBottom", "s")};
	grid-row: 3 / -1;
	grid-column: 1;
	margin-top: 40px;
`;

const Introduction = () => {
	return (
		<Container>
			<Inner>
				<Years>PART OF THE VIRTUAL REVOLUTION</Years>
				<Number>REVOLUTION 004 // 004</Number>
				<Title>
					EMERGING
					<br />
					ECONOMIES IN
					<br />
					THE VIRTUAL
					<br />
					REVOLUTION
				</Title>
				<Quotes>//</Quotes>
				<InventionsNumber>21 NOTABLE COMPANIES</InventionsNumber>
			</Inner>
		</Container>
	);
};

export default Introduction;
