import React from "react";
import styled from "@emotion/styled";
import {
	container,
	spacing,
	screen,
	fontSize,
} from "../../../../styles/mixins";
import Company from "./Company";
import { AnimatePresence } from "framer-motion";
import { useZoom } from "./ZoomState";
import Modal from "./Modal";
import { observer } from "mobx-react-lite";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";
import Introduction from "./Introduction";

const Container = styled.div`
	padding-bottom: 100vh;

	display: block;
	position: relative;
	z-index: 3;
	@media ${screen("ml")} {
		${container};
	}
`;

const CategoriesContainer = styled.div`
	grid-column: 25 / 45;
	grid-row: 1 / -1;
	position: relative;
	z-index: 4;
	display: grid;
	grid-template-columns: auto;
	grid-row-gap: 40px;

	${spacing("marginLeft", "m")};
	${spacing("marginRight", "m")};
	@media ${screen("ml")} {
		${spacing("marginTop", "xxl")};
		margin-left: 0;
		margin-right: 0;
	}
	@media ${screen("lg")} {
		grid-column: 23 / 43;
		grid-row: 1 / -1;
	}
`;
const CategoryContainer = styled.div`
	width: 100%;
	background: #eeecfd;
	border: 1px solid #08043c;
	@media ${screen("ml")} {
		border-radius: 30px;
	}
`;
const Title = styled.h4`
	${spacing("paddingTop", "s")};
	${spacing("paddingBottom", "s")};
	${abcMaxiPlusMono};
	line-height: 1;
	${fontSize(0.5)};
	padding-left: 20px;
	border-bottom: 1px solid ${({ theme }) => theme.cardForeground};
`;
const Table = styled.table`
	border-style: hidden;
	width: 100%;
	td {
		border: 1px solid ${({ theme }) => theme.cardForeground};
	}
`;

const colors = {
	GIG: "#1691F3",
	VIRTUAL: "#DF00C9",
	SHARING: "#E06C00",
	INFLUENCE: "#E84115",
	CRYPTO: "#F8D448",
};
const TimelineContainer = observer(({ categories }) => {
	const zoomState = useZoom();
	return (
		<Container>
			<Introduction />
			<CategoriesContainer>
				{categories.map(({ title, companies }) => (
					<CategoryContainer key={title}>
						<Title>{title.toUpperCase()} ECONOMY</Title>
						<Table>
							<tbody>
								{companies.map((company) => (
									<Company company={company} key={company.name} />
								))}
							</tbody>
						</Table>
					</CategoryContainer>
				))}
			</CategoriesContainer>

			<AnimatePresence>
				{zoomState.activeCard ? (
					<Modal
						key={zoomState.activeCard}
						company={zoomState.activeCardContent}
						colors={colors}
					/>
				) : null}
			</AnimatePresence>
		</Container>
	);
});

export default TimelineContainer;
