import React from "react";
import styled from "@emotion/styled";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";
import { fontSize } from "../../../../styles/mixins";

const Title = styled.h4`
	// ${abcMaxiPlusMono};
	${fontSize(0.5)};
	line-height: 1;
	padding: 20px;
`;

const Date = styled.td`
	${abcMaxiPlusMono};
	${fontSize(0.5)};
	line-height: 1;
	vertical-align: top;
	padding: 20px;
`;

const Content = styled.div`
	text-align: justify;
	hyphens: auto;
	${fontSize(-0.8)};
	line-height: 1.2;
	padding: 0 20px 20px 20px;
`;

const Invention = ({ invention }) => {
	const { name, rawDate, description } = invention;
	return (
		<tr>
			<Date>{rawDate} </Date>
			<td>
				<Title>{name}</Title>

				{description && <Content>{description}</Content>}
			</td>
		</tr>
	);
};
export default Invention;
