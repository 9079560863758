import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import Tile from "./Tile";
import { observer } from "mobx-react-lite";
import { useZoom } from "./ZoomState";
import Modal from "./Modal";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";

import Cover from "./Cover";
import { ThemeProvider } from "@emotion/react";
const Container = styled.div`
	display: grid;
	margin-left: auto;
	margin-right: auto;
	max-width: 1440px;
	padding-top: calc(150vh + 250px);
	padding-bottom: 100vh;
	position: relative;
	grid-row-gap: 50px;
	z-index: 3;
`;
const CoverContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const patterns = {
	"ambivalent tech": [
		{ row: "1 / 3", column: 112, parallax: 250, offset: 0, zIndex: 4 },
		{ row: "2 / 4", column: 570, parallax: 100, offset: 0, zIndex: 3 },
		{ row: "4 / 6", column: 223, parallax: -150, offset: 0, zIndex: 2 },
		{ row: "5 / 7", column: 780, parallax: 50, offset: 0, zIndex: 1 },
		{ row: "7 / 9", column: 443, parallax: -25, offset: 0, zIndex: 1 },
	],
	"future themes": [
		{ row: "1 / 3", column: 650, parallax: 250, offset: 0, zIndex: 4 },
		{ row: "2 / 4", column: 116, parallax: 100, offset: 0, zIndex: 3 },
		{ row: "4 / 6", column: 341, parallax: -150, offset: 0, zIndex: 2 },
		{ row: "5 / 7", column: 810, parallax: 50, offset: 0, zIndex: 1 },
		{ row: "7 / 9", column: 482, parallax: -25, offset: 0, zIndex: 1 },
	],
};

const ZoomContainer = observer(
	({ sections, chapter, decoration, title, theme }) => {
		const ref = useRef();

		const zoomState = useZoom();

		return (
			<Container ref={ref}>
				<ThemeProvider theme={theme}>
					<Cover
						sections={sections}
						chapter={chapter}
						decoration={decoration}
						title={title}
					/>
				</ThemeProvider>
				<ThemeProvider
					theme={{
						foreground: theme.cardForeground,
						background: theme.cardBackground,
						alignment: "largeModal",
						columns: 46,
					}}>
					{sections.map((section, i) => {
						return (
							<Tile
								section={section}
								key={i}
								index={i}
								total={sections.length}
								pattern={patterns[title.toLowerCase()][i]}
							/>
						);
					})}
					<AnimatePresence>
						{zoomState.activeCard ? (
							<Modal
								total={sections.length}
								key={zoomState.activeCard}
								section={zoomState.activeCardContent}
							/>
						) : null}
					</AnimatePresence>
				</ThemeProvider>
			</Container>
		);
	}
);

export default ZoomContainer;
