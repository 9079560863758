import React, { useLayoutEffect, useRef } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { abcMaxiPlusMono, pLead } from "../../../../styles/typography-act-2";
import { useZoom } from "./ZoomState";
import {
	container,
	fontSize,
	screen,
	spacing,
} from "../../../../styles/mixins";
import Portal from "../../../../util/Portal";
import CompanyDetails from "./CompanyDetails";
import { ThemeProvider } from "@emotion/react";
import { fluid } from "../../../../styles/functions";
import { positions } from "../../Structure/BodyCopy";
import ArrowSvg from "../../ArrowSvg";

const Outer = styled(motion.div)`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: 101;
`;
const Wrapper = styled.div`
	padding: 40px 0;
	height: 100%;
	${container};
`;
const Container = styled(motion.div)`
	border: 1px solid ${({ theme: { foreground } }) => foreground};
	background-color: #eeecfd;
	height: 100%;
	position: relative;
	color: ${({ theme: { foreground } }) => foreground};
	overflow: hidden;
	grid-column: 2 / -2;
	display: grid;
	grid-template-columns: repeat(46, 1fr);
	grid-template-rows: auto 1fr;
`;
const Inner = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	grid-column: 1 / -1;
	grid-row: 2;
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-width: thin;
	scrollbar-color: ${({ theme: { foreground } }) => foreground}
		${({ theme: { background } }) => background};
	&::-webkit-scrollbar {
		width: 11px;
	}
	&::-webkit-scrollbar-track {
		background: ${({ theme: { background } }) => background};
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme: { foreground } }) => foreground};
		border-radius: 6px;
		border: 3px solid ${({ theme: { background } }) => background};
	}
`;
const TitleContainer = styled.div`
	${container({ cols: 46 })};
	${spacing("paddingTop", "m")};
	top: 0;
	left: 0;
	width: 100%;
	@media ${screen("md")} {
		position: absolute;
	}
`;
const Title = styled(motion.div)`
	${abcMaxiPlusMono};
	font-size: ${fluid(22, 109, 320, 1440)};
	@media screen and (min-width: 1440px) {
		font-size: 109px;
	}
	font-weight: 400;
	line-height: 1;
	display: block;
	grid-column: 1 / 24;
	margin-left: 20px;
`;

const TopBar = styled.div`
	grid-column: 1 / -1;
	grid-row: 1;
	padding: 20px;
	border-bottom: 1px solid #04243e;
`;

const Close = styled(motion.button)`
	cursor: pointer;
	display: flex;
	align-items: center;
	${abcMaxiPlusMono};
	${fontSize(-1)};
	line-height: 1;

	&:focus {
		outline: none;
	}
	svg {
		width: 14px;
		height: 14px;
		transform: rotate(90deg);
		margin-right: 20px;
	}
	path {
		fill: ${({ theme: { foreground } }) => foreground};
	}
`;
const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;
const DescriptionContainer = styled.div`
	${container({ cols: 46 })};
	${spacing("paddingTop", "m")};
`;
const Description = styled.p`
	${pLead};
	text-align: justify;
	hyphens: auto;
	${positions.largeModal};
`;

const ContentContainer = styled(motion.div)`
	& > div:first-child {
		${spacing("marginTop", "l")};
	}
`;

const Modal = ({ company, total, colors }) => {
	const zoomState = useZoom();
	const ref = useRef();
	const scrollRef = useRef();

	useLayoutEffect(() => {
		const ref = scrollRef.current;
		disablePageScroll(ref);
		return () => {
			enablePageScroll(ref);
		};
	}, [scrollRef]);
	return (
		<Portal zIndex={101}>
			<Outer ref={ref}>
				<Overlay
					onClick={(e) => {
						zoomState.setActiveCard(null, null);
					}}
				/>
				<ThemeProvider
					theme={{
						foreground: "#08043C",
						background: "#EEECFD",
					}}>
					<Wrapper>
						<Container
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							layoutId={`container-${company.id}`}
							style={{ borderRadius: "24px" }}>
							<TopBar>
								<Close
									layoutId={`button-${company.id}`}
									onClick={(e) => {
										zoomState.setActiveCard(null, null);
									}}>
									<ArrowSvg />
									CLOSE RUBRIC
								</Close>
							</TopBar>

							<Inner ref={scrollRef}>
								<TitleContainer>
									<Title
										layout
										layoutId={`title-${company.id}`}
										dangerouslySetInnerHTML={{
											__html: company.name.split(" ").join("<br />"),
										}}
									/>
								</TitleContainer>
								<ContentContainer
									// layout={"size"}
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}>
									<DescriptionContainer>
										<Description>{company.description}</Description>
									</DescriptionContainer>
									<CompanyDetails company={company} />
								</ContentContainer>
							</Inner>
						</Container>
					</Wrapper>
				</ThemeProvider>
			</Outer>
		</Portal>
	);
};

export default Modal;
