import React, { useLayoutEffect, useRef } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";
import { useZoom } from "./ZoomState";
import importDirectory from "../../../../util/importDirectory";
import {
	container,
	fontSize,
	screen,
	spacing,
} from "../../../../styles/mixins";
import Portal from "../../../../util/Portal";
import { fluid } from "../../../../styles/functions";
import { ThemeProvider } from "@emotion/react";
import ArrowSvg from "../../ArrowSvg";
let components = importDirectory(
	require.context("../../", true, /Structure\/[a-zA-Z_-]*\.js$/),
	"default"
);
const Outer = styled(motion.div)`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: 101;
`;
const Wrapper = styled.div`
	padding: 40px 0;
	height: 100%;
	${container};
`;
const Container = styled(motion.div)`
	border: 1px solid ${({ theme: { foreground } }) => foreground};
	background-color: ${({ theme: { background } }) => background};
	height: 100%;
	position: relative;
	color: ${({ theme: { foreground } }) => foreground};
	overflow: hidden;
	grid-column: 2 / -2;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
`;
const Inner = styled.div`
	width: 100%;
	height: 100%;
	grid-column: 1 / -1;
	grid-row: 2;
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-width: thin;
	scrollbar-color: ${({ theme: { foreground } }) => foreground}
		${({ theme: { background } }) => background};
	&::-webkit-scrollbar {
		width: 11px;
	}
	&::-webkit-scrollbar-track {
		background: ${({ theme: { background } }) => background};
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme: { foreground } }) => foreground};
		border-radius: 6px;
		border: 3px solid ${({ theme: { background } }) => background};
	}
`;
const TitleContainer = styled.div`
	${container({ cols: 46 })};
	grid-template-rows: 1fr auto auto;
	justify-items: start;
	align-items: start;
	color: ${({ theme: { foreground } }) => foreground};
	@media screen and (min-width: 768px) {
		padding-top: 26px;
	}
`;
const Title = styled(motion.h3)`
	${abcMaxiPlusMono};
	font-size: 50px;
	@media screen and (min-width: 768px) {
		font-size: ${fluid(36, 72, 768, 1440)};
	}
	@media screen and (min-width: 1440px) {
		font-size: 72px;
	}
	font-weight: 400;
	line-height: 1;
	display: block;
	grid-row: 3;
	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: 25 / span 21;
	}

	@media ${screen("ml")} {
		grid-column: 25 / span 21;
	}

	@media ${screen("mx")} {
		grid-column: 25 / span 21;
	}
	@media screen and (min-width: 768px) {
		${spacing("marginTop", "l")};
	}
`;
const Meta = styled(motion.div)`
	${abcMaxiPlusMono};
	font-size: 12px;
	margin-bottom: 8px;
	line-height: 1;
	font-weight: 400;
	grid-column: 1 / -1;
	grid-row: 1;
	@media screen and (min-width: 768px) {
		grid-column: 2;
	}
	padding: 20px;
	border-bottom: 1px solid ${({ theme }) => theme.foreground};
`;

const Image = styled(motion.div)`
	grid-row: 1 / -1;
	grid-column: 1;
	height: 100%;
	//padding: 50px;
	border-right: 1px solid ${({ theme }) => theme.foreground};
	display: none;
	align-items: center;
	justify-items: center;
	pointer-events: none;
	@media screen and (min-width: 768px) {
		display: flex;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;

const MobileImage = styled(motion.div)`
	display: grid;
	grid-template-columns: repeat(46, 1fr);
	pointer-events: none;
	mix-blend-mode: lighten;
	@media screen and (min-width: 768px) {
		display: none;
	}
	img {
		grid-column: 3 / -3;
		max-height: 300px;
	}
`;

const Close = styled(motion.button)`
	position: absolute;
	z-index: 3;
	top: 65px;
	left: 10px;
	cursor: pointer;
	@media screen and (min-width: 768px) {
		top: 20px;
		left: 20px;
	}
	&:focus {
		outline: none;
	}
	svg {
		transform: rotate(-90deg);
	}
	path {
		fill: ${({ theme: { foreground } }) => foreground};
	}
`;
const CloseInner = styled.div`
	writing-mode: vertical-lr;
	display: flex;
	${fontSize(-1)};
	${abcMaxiPlusMono};
	line-height: 1;
	transform-origin: center center;
	transform: rotate(180deg);
	align-items: flex-end;

	svg {
		margin-top: 20px;
	}
`;
const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const ContentContainer = styled(motion.div)``;

const Modal = ({ section, total, chapter }) => {
	const zoomState = useZoom();
	const ref = useRef();

	const scrollRef = useRef();

	useLayoutEffect(() => {
		const ref = scrollRef.current;
		disablePageScroll(ref);
		return () => {
			enablePageScroll(ref);
		};
	}, [scrollRef]);
	return (
		<Portal zIndex={101}>
			<Outer ref={ref}>
				<Overlay
					onClick={(e) => {
						zoomState.setActiveCard(null, null);
					}}
				/>
				<Wrapper>
					<Container
						layoutId={`container-${section.title}`}
						style={{ borderRadius: "24px" }}>
						<Close
							layoutId={`button-${section.title}`}
							onClick={(e) => {
								zoomState.setActiveCard(null, null);
							}}>
							<CloseInner>
								CLOSE RUBRIC
								<ArrowSvg width={21} height={21} />
							</CloseInner>
						</Close>
						<Meta layoutId={`rubrik-${section.title}`}>
							RUBRIC {`${zoomState.index + 1}`.padStart(3, "0")} //{" "}
							{`${total}`.padStart(3, "0")}
						</Meta>
						<Image>
							<motion.img
								src={section.cardImage.publicURL}
								layoutId={`image-${section.title}`}
							/>
						</Image>
						<Inner ref={scrollRef}>
							<ThemeProvider theme={{ foreground: "#F4F4F4" }}>
								<MobileImage>
									<motion.img
										src={section.cardImage.publicURL}
										layoutId={`mobile-image-${section.title}`}
									/>
								</MobileImage>
								<TitleContainer>
									<Title
										layout
										layoutId={`title-${section.title}`}
										dangerouslySetInnerHTML={{
											__html: section.htmlTitle.toUpperCase(),
										}}
									/>
								</TitleContainer>

								<ContentContainer
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}>
									{section.body &&
										section.body.map((block, i) => {
											let Component = components[block.component];
											if (Component) {
												return <Component {...block} key={i} />;
											} else {
												return (
													<div key={i}>
														Missing Component: {block.component}
													</div>
												);
											}
										})}
								</ContentContainer>
							</ThemeProvider>
						</Inner>
					</Container>
				</Wrapper>
			</Outer>
		</Portal>
	);
};

export default Modal;
