import React, { useRef } from "react";
import styled from "@emotion/styled";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";
import { useZoom } from "./ZoomState";
import { motion } from "framer-motion";
import { fluid } from "../../../../styles/functions";
import { fontSize, spacing } from "../../../../styles/mixins";
import ArrowSvg from "../../ArrowSvg";
//This is position sticky with no top or left etc to fix a bug in safari where the position sticky cover glitches in and out in front of the cards......
const Wrapper = styled(motion.div)`
	margin: 0 auto;
	${spacing("marginBottom", "xl")};
	width: 800px;
	max-width: 90vw;
	justify-self: center;
	position: sticky;
	z-index: 5;
`;
const Container = styled(motion.div)`
	border: 1px solid ${({ theme: { foreground } }) => foreground};
	color: ${({ theme: { foreground } }) => foreground};
	${abcMaxiPlusMono};
	background-color: ${({ theme: { background } }) => background};
	position: relative;
	cursor: pointer;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr;
	overflow: hidden;
`;
const Title = styled(motion.h3)`
	font-size: ${fluid(12, 42, 320, 890)};

	@media screen and (min-width: 890px) {
		font-size: 42px;
	}
	color: #f4f4f4;
	font-weight: 400;
	line-height: 1;
	grid-row: 2;
	grid-column: 2;
	align-self: end;
	justify-self: start;
	padding: 20px;
`;
const ImageContainer = styled.div`
	grid-row: 1 / -1;
	grid-column: 1;
	border-right: 1px solid ${({ theme }) => theme.foreground};
`;
const Image = styled(motion.img)`
	height: 100%;
	width: 100%;
	object-fit: contain;
`;
const Open = styled(motion.button)`
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 3;
	svg {
		transform: rotate(90deg);
	}
	path {
		fill: ${({ theme: { foreground } }) => foreground};
	}
`;
const OpenInner = styled.div`
	writing-mode: vertical-lr;
	display: flex;
	${fontSize(-1)};
	line-height: 1;
	transform-origin: center center;
	transform: rotate(180deg);
	align-items: flex-end;

	svg {
		margin-top: 20px;
	}
`;
const Meta = styled(motion.div)`
	font-size: 12px;
	margin-bottom: 8px;
	line-height: 1;
	font-weight: 400;
	grid-column: 2;
	grid-row: 1;
	padding: 20px;
	border-bottom: 1px solid ${({ theme }) => theme.foreground};
	span {
		display: inline-block;
		&:last-child {
			margin: 0;
		}
	}
`;

const Tile = ({ section, index, total, chapter }) => {
	const ref = useRef();
	const zoomState = useZoom();

	return (
		<Wrapper>
			<Container
				style={{ borderRadius: "24px" }}
				layoutId={`container-${section.title}`}
				ref={ref}
				onClick={() => {
					zoomState.setActiveCard(section.title, section, index);
				}}>
				<Open layoutId={`button-${section.title}`}>
					<OpenInner>
						READ MORE
						<ArrowSvg width={21} height={21} />
					</OpenInner>
				</Open>
				<Meta layoutId={`rubrik-${section.title}`}>
					RUBRIC {`${index + 1}`.padStart(3, "0")} //{" "}
					{`${total}`.padStart(3, "0")}
				</Meta>
				<ImageContainer>
					<Image
						layoutId={`image-${section.title}`}
						src={section.cardImage.publicURL}
					/>
				</ImageContainer>

				<Title
					layoutId={`title-${section.title}`}
					dangerouslySetInnerHTML={{
						__html: section.htmlTitle.toUpperCase(),
					}}
				/>
			</Container>
		</Wrapper>
	);
};

export default Tile;
