import React from "react";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { transparentize } from "polished";
import { pSmall } from "../../styles/typography";
import { Link } from "gatsby";
import { blackOrWhite, spacing } from "../../styles/mixins";

const ButtonElement = styled(Link, {
	shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isFilled",
})`
	${pSmall}
	${spacing("padding", "m")};
	${(props) =>
		`border: 1px solid ${transparentize(0.7, blackOrWhite(props.theme))};`}
	border-radius: 500px;
	display: inline-block;
	color: ${(props) => blackOrWhite(props.theme, props.isFilled ? "bg" : "fg")};
	background-color: ${(props) =>
		props.isFilled ? transparentize(0.1, blackOrWhite(props.theme)) : "none"};
	transition: border-color 400ms ease-in-out, background-color 400ms ease-in-out;
	&:hover {
		border-color: ${(props) => blackOrWhite(props.theme)};
		background-color: ${(props) =>
			props.isFilled ? blackOrWhite(props.theme) : "none"};
	}
	&:focus {
		outline: none;
	}
`;

export default function Button({ children, isFilled, ...rest }) {
	return (
		<ButtonElement isFilled={isFilled} {...rest}>
			{children}
		</ButtonElement>
	);
}
