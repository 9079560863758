import { useCallback, useEffect, useRef } from "react";
import { usePageLayout } from "../contexts/PageLayout";
import { useGetResizer } from "../contexts/Resizer";

export function useSectionLayout({ data, index, chapterKey }) {
	let ref = useRef();
	const pageLayout = usePageLayout();
	const area = useGetResizer();
	const prevData = useRef();
	useEffect(() => {
		if (ref.current) {
			const rect = ref.current.getBoundingClientRect();
			const scrollY = window.scrollY;
			const labels = data.labels ? [...data.labels] : [];
			let section = {
				start: rect.top + scrollY,
				end: rect.bottom + scrollY,
				labels: labels,
				theme: data.theme,
				index: index,
			};
			prevData.current = data;

			pageLayout.addSection(chapterKey, index, section);
		}
	}, [area, pageLayout, chapterKey, index, data]);
	return useCallback(
		(node) => {
			if (node && node !== ref.current) {
				ref.current = node;

				const rect = ref.current.getBoundingClientRect();
				const scrollY = window.scrollY;
				const labels = data.labels ? [...data.labels] : [];
				let section = {
					start: rect.top + scrollY,
					end: rect.bottom + scrollY,
					labels: labels,
					theme: data.theme,
					index: index,
				};
				pageLayout.addSection(chapterKey, index, section);
			}
		},
		[data, index, chapterKey, pageLayout]
	);
}
