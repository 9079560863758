import React from "react";
import styled from "@emotion/styled";
import { container, spacing } from "../../../styles/mixins";
import { pLead } from "../../../styles/typography-act-2";
import { positions } from "./BodyCopy";
import { css } from "@emotion/react";

const Container = styled.div`
	${({ theme: { alignment, columns } }) =>
		container({ cols: columns ? columns : alignment === "modal" ? 36 : 48 })};
	${({ theme: { alignment } }) =>
		spacing(
			"marginTop",
			alignment === "modal" || alignment === "largeModal" ? "l" : "xl"
		)};
	${({ theme: { alignment } }) =>
		spacing(
			"marginBottom",
			alignment === "modal" || alignment === "largeModal" ? "xl" : "xl"
		)};
	z-index: 3;
	position: relative;
`;

const Content = styled.div`
	${({ theme: { alignment } }) => positions[alignment]};
	text-align: justify;
	hyphens: auto;
	//backdrop-filter: blur(10px);
	color: ${({ theme: { foreground } }) => foreground};
	${({ theme }) => {
		return theme.alignment !== "modal" && theme.alignment !== "largeModal"
			? css`
					background: ${theme.background}D7;
					${spacing("padding", "s")};
					border-radius: 20px;
					border: 1px solid ${theme.foreground}28;
			  `
			: null;
	}};
	will-change: transform;

	${pLead};

	a {
		border-bottom: 1px solid ${({ theme }) => theme.foreground}AC;
		transition: border-color 400ms ease;

		&:hover {
			border-bottom: 1px solid ${({ theme }) => theme.foreground}FF;
		}
	}
`;
const LeadParagraph = ({ content }) => {
	return (
		<Container>
			<Content dangerouslySetInnerHTML={{ __html: content }} />
		</Container>
	);
};

export default LeadParagraph;
