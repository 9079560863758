import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";
import { useZoom } from "./ZoomState";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { useGetResizer } from "../../../../contexts/Resizer";
import { getOffset } from "../../../../util/getOffset";
import { fluid } from "../../../../styles/functions";
import { fontSize } from "../../../../styles/mixins";
import ArrowSvg from "../../ArrowSvg";

function getPosition(pos) {
	return (pos + 420 / 2 - 1440 / 2) / 1440;
}

const Wrapper = styled(motion.div)`
	width: 420px;
	max-width: 80vw;
	justify-self: center;
	position: relative;
	z-index: ${({ layoutPattern }) => layoutPattern.zIndex};
	grid-row: ${({ index }) => index + 1};
	@media screen and (min-width: 450px) {
		left: ${({ layoutPattern }) =>
			getPosition(layoutPattern.column) * 100 * 0.2}%;
	}

	@media screen and (min-width: 900px) {
		grid-row: ${({ layoutPattern }) => layoutPattern.row};
		left: ${({ layoutPattern }) =>
			getPosition(layoutPattern.column) * 100 * 0.8}%;
	}

	@media screen and (min-width: 1100px) {
		left: ${({ layoutPattern }) => getPosition(layoutPattern.column) * 100}%;
	}

	grid-column: 1;
	margin-top: ${({ layoutPattern }) => layoutPattern.offset}px;
`;
const Container = styled(motion.div)`
	height: 280px;
	border: 1px solid ${({ theme: { foreground } }) => foreground};
	color: ${({ theme: { foreground } }) => foreground};
	${abcMaxiPlusMono};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	background-color: ${({ theme: { background } }) => background};
	position: relative;
	cursor: pointer;
`;
const Title = styled(motion.h3)`
	font-size: ${fluid(28, 40, 320, 1440)};
	@media screen and (min-width: 1440px) {
		font-size: 40px;
	}
	font-weight: 400;
	line-height: 1;
	padding: 20px;
`;
const Open = styled(motion.button)`
	display: flex;
	justify-items: center;
	font-variation-settings: "MONO" 1;
	font-weight: 400;
	line-height: 1;
	${fontSize(-1)};
	svg {
		margin-right: 20px;
		transform: rotate(-90deg);
	}
	path {
		fill: ${({ theme: { foreground } }) => foreground};
	}
`;

const TopBar = styled.div`
	padding: 20px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${({ theme: { foreground } }) => foreground};
`;
const Meta = styled(motion.div)`
	${fontSize(-1)};
	line-height: 1;
	font-variation-settings: "MONO" 1;
	span {
		display: inline-block;
		&:last-child {
			margin: 0;
		}
	}
`;
const Tile = ({ section, index, total, pattern }) => {
	const ref = useRef();
	const zoomState = useZoom();
	const area = useGetResizer();
	// const pattern = section.pattern;

	const [position, setPosition] = useState({ start: 0, end: 0 });

	const { scrollY } = useViewportScroll();
	let absParallax = Math.abs(pattern.parallax);
	const pos = useTransform(
		scrollY,
		[position.start - absParallax, position.end + absParallax],
		[(pattern.parallax / 2) * -1, pattern.parallax / 2],
		{
			clamp: true,
		}
	);
	useEffect(() => {
		const { top } = getOffset(ref.current);
		setPosition({
			start: top - window.innerHeight,
			end: top + ref.current.offsetHeight,
			top: top,
		});
	}, [area]);

	return (
		<Wrapper layoutPattern={pattern} style={{ y: pos }} index={index}>
			<Container
				style={{ borderRadius: "24px" }}
				layoutId={`container-${section.title}`}
				ref={ref}
				onClick={() => {
					zoomState.setActiveCard(section.title, section, index);
				}}>
				<TopBar>
					<Open layoutId={`button-${section.title}`}>
						<ArrowSvg />
						READ MORE
					</Open>
					<Meta layoutId={`rubrik-${section.title}`}>
						RUBRIC {`${index + 1}`.padStart(2, "0")} //{" "}
						{`${total}`.padStart("2", "0")}
					</Meta>
				</TopBar>

				<Title
					layoutId={`title-${section.title}`}
					dangerouslySetInnerHTML={{ __html: section.htmlTitle.toUpperCase() }}
				/>
			</Container>
		</Wrapper>
	);
};

export default Tile;
