export function getOffset(element) {
	let el = element,
		left = 0,
		top = 0;

	do {
		left += el.offsetLeft;
		top += el.offsetTop;

		el = el.offsetParent;
	} while (el);
	return { top, left };
}
