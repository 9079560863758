import React from "react";
import styled from "@emotion/styled";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";

import { fontSize } from "../../../../styles/mixins";
import { useZoom } from "./ZoomState";
import ArrowSvg from "../../ArrowSvg";

const Date = styled.td`
	${abcMaxiPlusMono};
	${fontSize(0.5)};
	font-weight: 400;
	line-height: 1;
	vertical-align: top;
	padding: 20px;
`;
const CompanyContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const CompanyName = styled.h5`
	${fontSize(0.5)};
	line-height: 1;
	padding: 20px;
`;
const Content = styled.div`
	text-align: justify;
	hyphens: auto;
	${fontSize(-0.8)};
	line-height: 1.2;
	padding: 0 20px 20px 20px;
`;

const Button = styled.button`
	${abcMaxiPlusMono};
	line-height: 1;
	${fontSize(-1)};
	align-self: flex-end;
	display: flex;
	margin: 0 20px 20px 0;
	align-items: center;
	&:focus {
		outline: 0;
	}

	svg {
		margin-left: 10px;
	}
`;
const Company = ({ company }) => {
	const zoomState = useZoom();
	return (
		<tr>
			<Date>{company.boom}</Date>
			<td>
				<CompanyContainer>
					<CompanyName>{company.name}</CompanyName>
					<Content>{company.description}</Content>
					<Button
						onClick={() => {
							zoomState.setActiveCard(company.name, company, 0);
						}}>
						READ MORE <ArrowSvg />
					</Button>
				</CompanyContainer>
			</td>
		</tr>
	);
};

export default Company;
