import React, { useLayoutEffect, useRef } from "react";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";
import { useZoom } from "./ZoomState";
import importDirectory from "../../../../util/importDirectory";
import {
	container,
	fontSize,
	screen,
	spacing,
} from "../../../../styles/mixins";
import Portal from "../../../../util/Portal";
import { fluid } from "../../../../styles/functions";
import ArrowSvg from "../../ArrowSvg";
let components = importDirectory(
	require.context("../../", true, /Structure\/[a-zA-Z_-]*\.js$/),
	"default"
);
const Outer = styled(motion.div)`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
	z-index: 101;
`;
const Wrapper = styled.div`
	padding: 40px 0;
	height: 100%;
	${container};
`;
const Container = styled(motion.div)`
	border: 1px solid ${({ theme: { foreground } }) => foreground};
	display: grid;
	background-color: ${({ theme: { background } }) => background};
	height: 100%;
	position: relative;
	color: ${({ theme: { foreground } }) => foreground};
	overflow: hidden;
	grid-column: 2 / -2;
	grid-template-columns: repeat(46, 1fr);
	grid-template-rows: auto 1fr;
`;
const TopBar = styled.div`
	grid-column: 1 / -1;
	grid-row: 1;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid ${({ theme: { foreground } }) => foreground};
`;
const Inner = styled.div`
	grid-column: 1 / -1;
	grid-row: 2;
	width: 100%;
	height: 100%;
	position: relative;
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-width: thin;
	scrollbar-color: ${({ theme: { foreground } }) => foreground}
		${({ theme: { background } }) => background};
	&::-webkit-scrollbar {
		width: 11px;
	}
	&::-webkit-scrollbar-track {
		background: ${({ theme: { background } }) => background};
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme: { foreground } }) => foreground};
		border-radius: 6px;
		border: 3px solid ${({ theme: { background } }) => background};
	}
`;

const TitleContainer = styled.div`
	${container({ cols: 46 })};
	justify-items: start;
	${spacing("paddingTop", "m")};

	width: 100%;
	top: 0;
	left: 0;
	@media ${screen("md")} {
		position: absolute;
	}
`;
const Title = styled(motion.div)`
	${abcMaxiPlusMono};
	font-size: ${fluid(22, 72, 320, 1440)};
	@media screen and (min-width: 1440px) {
		font-size: 72px;
	}
	margin-bottom: 10px;
	font-weight: 400;
	line-height: 1;
	display: block;
	grid-column: 1 / 24;
	margin-left: 20px;
`;
const Meta = styled(motion.div)`
	${abcMaxiPlusMono};
	${fontSize(-1)};
	line-height: 1;
	letter-spacing: 0.045em;
	grid-row: 2;
	grid-column: 3 / -3;
`;

const Close = styled(motion.button)`
	cursor: pointer;
	display: flex;
	align-items: center;
	${abcMaxiPlusMono};
	${fontSize(-1)};
	line-height: 1;

	&:focus {
		outline: none;
	}
	svg {
		width: 14px;
		height: 14px;
		margin-right: 20px;
		transform: rotate(90deg);
	}
	path {
		//stroke-width: 6;
		fill: ${({ theme: { foreground } }) => foreground};
	}
`;
const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const ContentContainer = styled(motion.div)`
	& > div:first-child {
		${spacing("marginTop", "l")};
	}
`;

const Modal = ({ section, total }) => {
	const zoomState = useZoom();
	const ref = useRef();

	const scrollRef = useRef();

	useLayoutEffect(() => {
		let ref = scrollRef.current;
		disablePageScroll(ref);
		return () => {
			enablePageScroll(ref);
		};
	}, [scrollRef]);
	return (
		<Portal zIndex={101}>
			<Outer ref={ref}>
				<Overlay
					onClick={(e) => {
						zoomState.setActiveCard(null, null);
					}}
				/>
				<Wrapper>
					<Container
						layoutId={`container-${section.title}`}
						style={{ borderRadius: "24px" }}>
						<TopBar>
							<Close
								layoutId={`button-${section.title}`}
								onClick={(e) => {
									zoomState.setActiveCard(null, null);
								}}>
								<ArrowSvg />
								CLOSE RUBRIC
							</Close>
							<Meta layoutId={`rubrik-${section.title}`}>
								RUBRIC {`${zoomState.index + 1}`.padStart(2, "0")} //{" "}
								{`${total}`.padStart("2", "0")}
							</Meta>
						</TopBar>
						<Inner ref={scrollRef}>
							<TitleContainer>
								<Title
									layout
									layoutId={`title-${section.title}`}
									dangerouslySetInnerHTML={{
										__html: section.htmlTitle.toUpperCase(),
									}}
								/>
							</TitleContainer>
							<ContentContainer
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}>
								{section.body &&
									section.body.map((block, i) => {
										let Component = components[block.component];
										if (Component) {
											return <Component {...block} key={i} />;
										} else {
											return (
												<div key={i}>Missing Component: {block.component}</div>
											);
										}
									})}
							</ContentContainer>
						</Inner>
					</Container>
				</Wrapper>
			</Outer>
		</Portal>
	);
};

export default Modal;
