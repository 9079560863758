import React from "react";

import { ZoomProvider } from "./Zoom2/ZoomState";
import ZoomContainer from "./Zoom2/ZoomContainer";
import { LayoutGroup } from "framer-motion";
const Zoom2 = ({ theme, ...props }) => {
	return (
		<ZoomProvider>
			<LayoutGroup>
				<ZoomContainer {...props} theme={theme} />
			</LayoutGroup>
		</ZoomProvider>
	);
};
export default Zoom2;
