import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";
import { observer } from "mobx-react-lite";
import { useNav } from "./NavContext";
import { useUIState } from "../../../../contexts/UIStore";
import { useGetResizer } from "../../../../contexts/Resizer";
import { ThemeProvider } from "@emotion/react";
const Wrapper = styled.div`
	position: sticky;
	top: ${({ topNavHeight, topNavOpen }) => (topNavOpen ? topNavHeight : 0)}px;
	background-color: ${({ theme: { background } }) => background};
	transition: top 300ms ease-in-out, opacity 300ms, color 300ms ease-in-out,
		background-color 300ms ease-in-out;
	z-index: 4;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	border-top: 1px solid rgba(0, 0, 0, 0.3);
	overflow: hidden;
	display: flex;
	flex: 1;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;
const Container = styled.div`
	padding: 20px;
	width: min-content;
	min-width: 100%;
	display: flex;
	//grid-column-gap: 1fr;
	overflow-x: auto;

	justify-content: space-between;
`;
const NavItem = styled.a`
	opacity: ${({ active }) => (active ? 1 : 0.3)};
	color: ${({ theme }) => theme.foreground};
	transition: opacity 0.3s, color 300ms ease-in-out;
	display: flex;
	${abcMaxiPlusMono};
	font-weight: 400;
	align-items: center;
	font-size: 12px;
	margin-right: 10px;
	&:last-child {
		margin-right: 0;
	}
`;
const Num = styled.div`
	margin-right: 8px;
	padding: 0 5px;
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	border-radius: 500px;
	transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
	background-color: ${({ theme: { foreground } }) => foreground};
	color: ${({ theme: { background } }) => background};
	${abcMaxiPlusMono}
`;
const Navigation = observer(({ revolutions, show }) => {
	const nav = useNav();
	const uiState = useUIState();
	const ref = useRef();
	const area = useGetResizer();
	useEffect(() => {
		const rect = ref.current.getBoundingClientRect();
		nav.setHeight(rect.height);
	}, [area, nav]);

	return (
		<ThemeProvider
			theme={{
				foreground: nav.theme.foreground,
				background: nav.theme.background,
			}}>
			<Wrapper
				topNavHeight={uiState.navHeight}
				topNavOpen={uiState.navOpen}
				isVisible={show}>
				<Container ref={ref}>
					{revolutions.map(({ revolution, shortTitle }, i) => {
						return (
							<NavItem
								href={`#revolution-${shortTitle}`}
								key={revolution.id}
								active={nav.revolution === revolution.id}>
								<Num>{`${i + 1}`.padStart(2, "0")}</Num>
								{shortTitle.toUpperCase()} REVOLUTION
							</NavItem>
						);
					})}
				</Container>
			</Wrapper>
		</ThemeProvider>
	);
});

export default Navigation;
