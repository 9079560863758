import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { fontSize, spacing, screen } from "../../../../styles/mixins";
import { abcMaxiPlusMono, fontSurt } from "../../../../styles/typography-act-2";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { useGetResizer } from "../../../../contexts/Resizer";

const ContentContainer = styled.div`
	z-index: 3;

	align-self: flex-start;
	display: flex;
	${spacing("marginLeft", "m")};
	${spacing("marginRight", "m")};

	@media ${screen("ml")} {
		position: sticky;
		height: 100vh;
		top: 0;
		left: 40px;
		width: 50%;
		${spacing("marginBottom", "xxl")};
		margin-left: 0;
		margin-right: 0;
	}
`;
const Inner = styled(motion.div)`
	//padding: 6% 8% 6% 8%;
	position: relative;
	background: ${({ theme }) => theme.cardBackground};
	display: grid;
	grid-template-columns: 54px 1fr;
	grid-template-rows: auto auto auto auto;
	grid-column-gap: 26px;

	color: ${({ theme }) => theme.cardForeground};
	margin: auto;
	&:before {
		content: "";
		position: absolute;
		left: 54px;
		top: 0;
		height: 100%;
		border-left: 1px solid ${({ theme }) => theme.cardForeground};
	}
	border: 1px solid ${({ theme }) => theme.cardForeground};

	@media ${screen("ml")} {
		border-radius: 30px;
		//padding: 6% calc(13.888% + 40px) 6% 8%;
	}
`;
const Content = styled.div`
	text-align: justify;
	hyphens: auto;
	grid-row: 3;
	grid-column: 2;
	${spacing("marginBottom", "l")};
	${spacing("marginTop", "s")};
	padding-right: 26px;
	@media ${screen("ml")} {
		padding-right: calc(13.888% + 40px);
	}
	p {
		${fontSize(0)};
		${fontSurt};
		font-feature-settings: "salt" on;
		line-height: 1.6;
		${spacing("marginBottom", "s")};

		&:first-child {
			margin-top: 0;
			text-indent: 40px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;
const Number = styled.div`
	${abcMaxiPlusMono};
	line-height: 1;
	//font-weight: 400;
	${fontSize(-1)};
	color: ${({ theme }) => theme.cardForeground};
	grid-row: 1;
	grid-column: 2;
	${spacing("marginTop", "s")};
`;
const Title = styled.h3`
	${abcMaxiPlusMono};
	font-weight: 400;
	${fontSize(3.8)};
	color: ${({ theme }) => theme.cardForeground};
	line-height: 0.94;
	padding-left: 80px;
	${spacing("marginTop", "l")};
	${spacing("paddingBottom", "s")};

	grid-row: 2;
	grid-column: 1 / -1;
	border-bottom: 1px solid ${({ theme }) => theme.cardForeground};
`;

const Quotes = styled.div`
	grid-row: 4;
	grid-column: 2;
	${abcMaxiPlusMono};
	${fontSize(-1)};
	${spacing("marginBottom", "s")};
	line-height: 1;
`;

const Years = styled.div`
	grid-row: 1/3;
	grid-column: 1;
	writing-mode: vertical-lr;
	transform-origin: center center;
	transform: rotate(180deg);
	${abcMaxiPlusMono};
	${fontSize(-1)};
	line-height: 1;
	align-self: start;
	justify-self: center;
	${spacing("marginTop", "s")};
`;

const InventionsNumber = styled.div`
	writing-mode: vertical-lr;
	transform-origin: center center;
	transform: rotate(180deg);
	${abcMaxiPlusMono};
	${fontSize(-1)};
	line-height: 1;
	align-self: end;
	justify-self: center;
	${spacing("marginBottom", "s")};
	grid-row: 2 / -1;
	grid-column: 1;
`;

const Introduction = ({
	index,
	shortTitle,
	content,
	position,
	total,
	start,
	end,
}) => {
	const { scrollY } = useViewportScroll();
	const [height, setHeight] = useState(0);
	const heightDiff = position.winHeight - height;
	const ref = useRef();
	const area = useGetResizer();
	useEffect(() => {
		const rect = ref.current.getBoundingClientRect();
		setHeight(rect.height);
	}, [area]);
	const y = useTransform(
		scrollY,
		[position.start, position.end],
		[0, position.winWidth >= 1024 ? (heightDiff < 0 ? heightDiff : 0) : 0]
	);
	return (
		<ContentContainer>
			<Inner ref={ref} style={{ y: y }}>
				<Years>
					{start} - {end ?? "CURRENT"}
				</Years>
				<Number>REVOLUTION 00{index + 1} // 004</Number>
				<Title>
					{shortTitle.toUpperCase()}
					<br />
					REVOLUTION
				</Title>
				<Content dangerouslySetInnerHTML={{ __html: content }} />
				<Quotes>//</Quotes>
				<InventionsNumber>{total} NOTABLE INVENTIONS</InventionsNumber>
			</Inner>
		</ContentContainer>
	);
};

export default Introduction;
