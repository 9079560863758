import styled from "@emotion/styled";
import { container } from "../../../../styles/mixins";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";
import { fluid } from "../../../../styles/functions";
import React, { useEffect, useRef } from "react";
import { useShuffle } from "../../../../util/useShuffle";

const CoverContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;
const CoverEl = styled.div`
	height: 100vh;
	padding: 60px 0;
	position: sticky;
	top: 0;
	${container};
	align-items: center;
	grid-template-rows: auto 1fr auto;
	color: ${({ theme: { foreground } }) => foreground};
	${abcMaxiPlusMono};
`;

const Title = styled.h3`
	grid-row: 2;
	grid-column: 1 / -1;
	font-size: ${fluid(22, 60, 320, 1440)};
	@media screen and (min-width: 1440px) {
		font-size: 60px;
	}
	line-height: 1;
	text-align: center;
	letter-spacing: 0.4em;
	transition: opacity 0.1s;
`;

const Rubriks = styled.div`
	grid-column: 14 / 31;
	grid-row: 1;

	font-size: 12px;
	transition: opacity 0.1s;
`;
const ChapterTitle = styled.div`
	grid-column: 6 / 43;
	grid-row: 3;
	font-size: 12px;
	transition: opacity 0.1s;
`;
const Quote = styled.div`
	grid-column: 32 / 34;
	grid-row: 1;
	font-size: 12px;
	transition: opacity 0.1s;
`;

const Cover = ({ chapter, sections, title }) => {
	const coverRef = useRef();
	const [titleRef, startTitle, stopTitle] = useShuffle({
		repeat: false,
		triggerWhenInView: false,
	});

	useEffect(() => {
		let hasBeenVisible = false;
		const observer = new IntersectionObserver(
			(entries) => {
				for (const entry of entries) {
					if (entry.isIntersecting) {
						hasBeenVisible = true;
						startTitle();
					} else {
						if (hasBeenVisible) {
							stopTitle(true);
						}
					}
				}
			},
			{ rootMargin: "0px 0px -100% 0px" }
		);
		observer.observe(coverRef.current);
		return () => {
			stopTitle();
			observer.disconnect();
		};
	}, [startTitle, stopTitle]);

	return (
		<CoverContainer>
			<CoverEl ref={coverRef}>
				<Title ref={titleRef}>{title.toUpperCase()}</Title>
			</CoverEl>
		</CoverContainer>
	);
};
export default Cover;
