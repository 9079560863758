import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { shuffleLetters } from "./shuffle";

export function useShuffle({
	initialIterations = 16,
	minRepeatIterations = 8,
	repeat = false,
	inViewOptions = {},
	minTime = 4000,
	maxTime = 8000,
	triggerWhenInView = true,
}) {
	const ref = useRef();
	const timeouts = useRef([]);
	const shuffleTimeouts = useRef([]);
	const repeatTimeout = useRef();
	const allElements = useRef([]);
	const stopAnimation = useCallback(
		(reverse = false) => {
			if (ref.current && !reverse) {
				ref.current.style.opacity = 0;
			}

			for (const timeout of timeouts.current) {
				clearTimeout(timeout);
			}
			timeouts.current = [];
			for (const shuffleTimeout of shuffleTimeouts.current) {
				shuffleTimeout && shuffleTimeout();
			}
			shuffleTimeouts.current = [];
			clearTimeout(repeatTimeout.current);
			if (reverse) {
				allElements.current.forEach(({ element, text }, i) => {
					shuffleTimeouts.current[i] = shuffleLetters(element, {
						iterations: 4,
						reverse: true,
						text: text,
					});
				});
			}
		},
		[timeouts, shuffleTimeouts]
	);
	const startAnimation = useCallback(() => {
		stopAnimation();

		const elements = allElements.current;

		ref.current.style.opacity = 1;

		elements.forEach(({ element, text }, i) => {
			shuffleTimeouts.current[i] = shuffleLetters(element, {
				iterations: initialIterations,
				alterLength: true,
				text: text,
			});
		});
		if (repeat) {
			const cb = () => {
				repeatTimeout.current = setTimeout(() => {
					let iter = minRepeatIterations + Math.floor(Math.random() * 6);
					elements.forEach(({ element, text }, i) => {
						shuffleTimeouts.current[i] && shuffleTimeouts.current[i]();
						shuffleTimeouts.current[i] = shuffleLetters(element, {
							iterations: iter,
							alterLength: false,
							text: text,
						});
					});
					cb();
				}, minTime + Math.random() * (maxTime - minTime));
			};
			cb();
		}
	}, [
		initialIterations,
		minRepeatIterations,
		repeat,
		minTime,
		maxTime,
		stopAnimation,
	]);

	useLayoutEffect(() => {
		let hasBeenVisible = false;
		let observer = new IntersectionObserver((entries) => {
			for (const entry of entries) {
				if (entry.isIntersecting) {
					startAnimation();
					hasBeenVisible = true;
				} else {
					if (hasBeenVisible) {
						stopAnimation(true);
					}
				}
			}
		}, inViewOptions);
		if (triggerWhenInView) {
			observer.observe(ref.current);
		}
		return () => {
			observer.disconnect();
		};
	}, [startAnimation, stopAnimation]);

	const refCallback = useCallback(
		(node) => {
			ref.current = node;
			if (node) {
				if (!allElements.current.length) {
					ref.current.style.opacity = 0;

					let elements = [];
					const createChild = (element) => {
						let innerEl = document.createElement("div");
						innerEl.style.position = "absolute";
						innerEl.style.top = "0";
						innerEl.style.left = "0";
						innerEl.style.height = "100%";
						innerEl.style.width = "100%";
						innerEl.style.visibility = "visible";
						innerEl.style.whiteSpace = "nowrap";
						innerEl.innerText = element.textContent;
						return innerEl;
					};
					const gatherElements = (element) => {
						if (element.children.length) {
							Array.prototype.slice
								.call(element.children)
								.forEach((element) => {
									gatherElements(element);
								});
						} else {
							let inner = createChild(element);
							element.style.visibility = "hidden";
							element.style.position = "relative";
							element.appendChild(inner);
							elements.push({
								element: inner,
								outer: element,
								text: inner.textContent,
							});
						}
					};
					gatherElements(node);
					allElements.current = elements;
				}
			} else {
				stopAnimation();
				allElements.current.forEach(({ element, outer, text }) => {
					outer.visibility = "visible";
					outer.position = "static";
					outer.innerText = text;
				});
				allElements.current = [];
			}
		},
		[ref, stopAnimation]
	);
	return [refCallback, startAnimation, stopAnimation];
}
