import { createContext, useContext, useRef } from "react";
import { makeAutoObservable } from "mobx";
import React from "react";

let ZoomContext = createContext(null);

export const useZoom = () => {
	return useContext(ZoomContext);
};

export const ZoomProvider = ({ defaultRevolution = null, ...props }) => {
	const ref = useRef(
		makeAutoObservable({
			activeCardContent: null,
			activeCard: null,
			index: 0,
			setActiveCard(activeCard, activeCardContent, index) {
				this.activeCard = activeCard;
				this.activeCardContent = activeCardContent;
				this.index = index;
			},
		})
	);
	return <ZoomContext.Provider value={ref.current} {...props} />;
};
