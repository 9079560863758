import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { container, fontSize, spacing } from "../../../styles/mixins";
import { abcMaxiPlusMono } from "../../../styles/typography-act-2";
import { useShuffle } from "../../../util/useShuffle";
import { css, ThemeProvider } from "@emotion/react";
import { useSectionLayout } from "../../../util/useSectionLayout";
import { fluid } from "../../../styles/functions";
const Wrapper = styled.div`
	height: 200vh;
	position: relative;
	${spacing("marginBottom", "xxl")};
	z-index: 3;
	scroll-margin-top: -15px;
`;
const Container = styled.div`
	${container};
	grid-row-gap: 40px;

	height: 100vh;
	align-content: center;
	position: sticky;
	top: 0;
`;
const TitleEl = styled.h2`
	grid-column: 3 / -3;
	grid-row: 2;
	${abcMaxiPlusMono};
	font-size: ${fluid(32, 157)};
	font-variation-settings: "MONO" 1;
	text-align: justify;
	font-weight: 400;
	line-height: 0.94;
	font-feature-settings: "ss06" on, "ss07" on, "ss05" on;
`;

const MetaData = styled.div`
	${abcMaxiPlusMono};
	font-weight: 400;
	${({ theme: { layout } }) => layout.metadata};
	${fontSize(-0.8)};
`;
const ChapterInfo = styled.div`
	${abcMaxiPlusMono};
	font-weight: 400;
	${({ theme: { layout } }) => layout.chapter};
	${fontSize(-0.8)};
	display: flex;
	justify-content: space-between;
`;
const TitleLine = styled.div`
	display: flex;
	justify-content: space-between;
`;

const layouts = {
	1: {
		chapter: css`
			grid-column: 17 / -3;
			max-width: 200px;
			grid-row: 1;
		`,
		metadata: css`
			grid-column: 33 / -3;
			grid-row: 3;
		`,
	},
	2: {
		chapter: css`
			grid-column: 3 / -3;
			max-width: 200px;
			grid-row: 3;
		`,
		metadata: css`
			grid-column: 21 / -3;
			grid-row: 1;
		`,
	},
	3: {
		chapter: css`
			grid-column: 27 / -3;
			max-width: 200px;
			grid-row: 1;
		`,
		metadata: css`
			grid-column: 27 / -3;
			grid-row: 3;
		`,
	},
	4: {
		chapter: css`
			grid-column: 32 / -3;
			max-width: 200px;
			grid-row: 3;
		`,
		metadata: css`
			grid-column: 24 / -3;
			grid-row: 3;
		`,
	},
	5: {
		chapter: css`
			grid-column: 17 / -3;
			max-width: 200px;
			grid-row: 1;
		`,
		metadata: css`
			grid-column: 31 / -3;
			grid-row: 3;
		`,
	},
	6: {
		chapter: css`
			grid-column: 33 / -3;
			max-width: 200px;
			grid-row: 1;
		`,
		metadata: css`
			grid-column: 29 / -3;
			grid-row: 3;
		`,
	},
	7: {
		chapter: css`
			grid-column: 33 / -3;
			max-width: 200px;
			grid-row: 3;
		`,
		metadata: css`
			grid-column: 8 / -3;
			grid-row: 3;
		`,
	},
};

const Title = ({
	title,
	subTitle,
	meta = "",
	layout = 1,
	chapterKey,
	words,
}) => {
	const data = useRef({ labels: ["title"] });
	const cbRef = useSectionLayout({
		data: data.current,
		chapterKey,
		index: 0,
	});
	const ref = useRef();
	const [visible, setVisible] = useState(false);
	const [titleRef, startTitle, stopTitle] = useShuffle({
		repeat: false,
		triggerWhenInView: false,
		reverse: true,
	});
	const [metadataRef, startMetadata, stopMetadata] = useShuffle({
		triggerWhenInView: false,
	});
	const [chapterInfoRef, startChapterInfo, stopChapterInfo] = useShuffle({
		triggerWhenInView: false,
	});

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				for (const entry of entries) {
					if (entry.isIntersecting) {
						startTitle();
						startMetadata();
						startChapterInfo();
						setVisible(true);
					} else {
						stopTitle(true);
						stopMetadata(true);
						stopChapterInfo(true);
						setVisible(false);
					}
				}
			},
			{ rootMargin: "0px 0px -100% 0px" }
		);
		observer.observe(ref.current);
		return () => {
			stopTitle();
			stopMetadata();
			stopChapterInfo();
			observer.disconnect();
		};
	}, [
		startMetadata,
		startChapterInfo,
		stopMetadata,
		stopChapterInfo,
		startTitle,
		stopTitle,
	]);

	return (
		<ThemeProvider theme={{ layout: layouts[layout] }}>
			<Wrapper ref={cbRef} id={chapterKey}>
				<Container ref={ref}>
					<MetaData ref={metadataRef}>
						<div>{meta.toUpperCase()}</div>
						<div>WORDS // {words}</div>
					</MetaData>
					<TitleEl ref={titleRef}>
						{title.map((line, i) => (
							<TitleLine key={i}>
								{line.split(" ").map((word, j) => (
									<div key={j}>{word.toUpperCase()}</div>
								))}
							</TitleLine>
						))}
					</TitleEl>
					<ChapterInfo ref={chapterInfoRef}>
						<span>{subTitle.toUpperCase()}</span>
						<span>— 08</span>
					</ChapterInfo>
				</Container>
			</Wrapper>
		</ThemeProvider>
	);
};

export default Title;
