import React from "react";

const ArrowSvg = ({ width = 14, height = 14 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14 13.3333L14 8.66674L14 7.10465L14 0L6.89549 -3.10547e-07L5.33339 -3.7883e-07L0.666666 -5.82819e-07L0.666666 1.33333L5.33339 1.33333L6.89549 1.33333C8.67286 1.33333 9.56518 3.4769 8.31907 4.73812L0.19526 12.8619C-0.0650888 13.1223 -0.0650889 13.5444 0.19526 13.8047C0.455612 14.0651 0.87772 14.0651 1.13807 13.8047L5.8047 9.13811L5.80474 9.13815L5.80479 9.13807L9.25245 5.69043C10.5124 4.4305 12.6667 5.32284 12.6667 7.10465L12.6667 8.66674L12.6667 13.3333L14 13.3333Z"
				fill="#08043B"
			/>
		</svg>
	);
};

export default ArrowSvg;
