import { createContext, useContext, useRef } from "react";
import { makeAutoObservable } from "mobx";
import React from "react";

let NavContext = createContext(null);

export const useNav = () => {
	return useContext(NavContext);
};

export const NavProvider = ({
	defaultRevolution = null,
	defaultTheme,
	...props
}) => {
	const ref = useRef(
		makeAutoObservable({
			revolution: defaultRevolution,
			theme: defaultTheme,
			height: 80,
			setRevolution(revolution) {
				this.revolution = revolution;
			},
			setHeight(height) {
				this.height = height;
			},
			setTheme(theme) {
				this.theme = theme;
			},
		})
	);
	return <NavContext.Provider value={ref.current} {...props} />;
};
