import React, { useRef } from "react";
import styled from "@emotion/styled";
import Tile from "./Tile";
import { observer } from "mobx-react-lite";
import { useZoom } from "./ZoomState";
import Modal from "./Modal";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import Cover from "./Cover";
import { ThemeProvider } from "@emotion/react";
const Container = styled.div`
	padding-top: calc(150vh + 250px);
	padding-bottom: 100vh;
	position: relative;
	z-index: 3;
`;
const CoverContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const ZoomContainer = observer(
	({ sections, chapter, decoration, title, theme }) => {
		const ref = useRef();

		const zoomState = useZoom();

		return (
			<Container ref={ref}>
				<ThemeProvider theme={theme}>
					<Cover
						sections={sections}
						chapter={chapter}
						decoration={decoration}
						title={title}
					/>
				</ThemeProvider>
				<ThemeProvider
					theme={{
						foreground: theme.cardForeground,
						background: theme.cardBackground,
						alignment: "largeModal",
						columns: 46,
					}}>
					{sections.map((section, i) => {
						return (
							<Tile
								chapter={chapter}
								section={section}
								key={i}
								index={i}
								total={sections.length}
							/>
						);
					})}
					<AnimatePresence>
						{zoomState.activeCard ? (
							<Modal
								chapter={chapter}
								total={sections.length}
								key={zoomState.activeCard}
								section={zoomState.activeCardContent}
								decoration={decoration}
							/>
						) : null}
					</AnimatePresence>
				</ThemeProvider>
			</Container>
		);
	}
);

export default ZoomContainer;
