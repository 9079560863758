import React from "react";
import styled from "@emotion/styled";
import { spacing, fontSize } from "../../../styles/mixins";
import { abcMaxiPlusMono } from "../../../styles/typography-act-2";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useShuffle } from "../../../util/useShuffle";
const Container = styled.div`
	${spacing("marginTop", "xxxl")};
	${spacing("marginBottom", "xxxl")};
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	z-index: 3;
	@media screen and (min-width: 600px) {
		align-items: flex-start;
		flex-direction: row;
		justify-content: space-evenly;
	}
`;

const Block = styled.div`
	width: 240px;
	max-width: 90%;
	padding-top: 70px;

	position: relative;
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
	@media screen and (min-width: 600px) {
		max-width: 24%;
		margin: 0;
	}
`;
const Title = styled.h5`
	${abcMaxiPlusMono};
	font-weight: 400;
	${fontSize(-1.6)};
	letter-spacing: 0.02em;
`;
const Content = styled.div`
	${fontSize(1)};
	font-feature-settings: "salt" on;
	text-align: justify;
	font-weight: 400;
	${abcMaxiPlusMono};
	font-variation-settings: "MONO" 1;
	line-height: 1.6;
	${spacing("marginTop", 3.8)};
`;
const Arrow = styled.span`
	display: inline-block;
`;
const Lines = styled(motion.div)`
	position: absolute;
	width: 100%;
	border-left: 1px solid ${({ theme: { foreground } }) => foreground};
	border-right: 1px solid ${({ theme: { foreground } }) => foreground};
	height: 10px;
	top: 0;
	left: 0;
	transform-origin: center top;
	&:after {
		content: "";
		position: absolute;
		left: 12px;
		top: 0;
		height: 10px;
		width: 1px;
		background-color: ${({ theme: { foreground } }) => foreground};
	}
`;

const BlockComponent = ({ title, content, i }) => {
	const { ref, inView } = useInView({ delay: 700 });
	const [shuffleTitleRef] = useShuffle({});
	const [shuffleNumberRef] = useShuffle({});
	return (
		<Block key={i} ref={ref}>
			<Lines
				animate={{ scaleY: inView ? 1 : 0 }}
				transition={{ type: "tween", duration: 0.3, delay: i * 0.1 }}
			/>
			<Title>
				<span ref={shuffleTitleRef}>{title}</span>
				<br />
				<Arrow>
					<svg
						width="11"
						height="8"
						viewBox="0 0 11 8"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path d="M0 4H10M10 4L7 1M10 4L7 7" stroke="#F3EEEB" />
					</svg>
				</Arrow>{" "}
				<span ref={shuffleNumberRef}>{`${i + 1}`.padStart(3, "0")}</span>
			</Title>
			<Content>{content}</Content>
		</Block>
	);
};
const BlockList = ({ blocks }) => {
	return (
		<Container>
			{blocks.map(({ title, content }, i) => {
				return <BlockComponent i={i} key={i} title={title} content={content} />;
			})}
		</Container>
	);
};

export default BlockList;
