import React from "react";
import styled from "@emotion/styled";
import Revolution from "./Timeline/Revolution";
import Navigation from "./Timeline/Navigation";
import { NavProvider } from "./Timeline/NavContext";
import { useInView } from "react-intersection-observer";

const Container = styled.div``;
const Timeline = ({ revolutions }) => {
	return (
		<NavProvider
			defaultRevolution={revolutions[0].name}
			defaultTheme={revolutions[0].theme}>
			<Container>
				{revolutions.map(({ revolution, content, shortTitle, theme }, i) => {
					return (
						<Revolution
							revolution={revolution}
							shortTitle={shortTitle}
							total={revolutions.length}
							content={content}
							key={revolution.id}
							theme={theme}
							index={i}
						/>
					);
				})}
			</Container>
		</NavProvider>
	);
};

export default Timeline;
