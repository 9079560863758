import React, { useRef } from "react";
import styled from "@emotion/styled";
import { abcMaxiPlusMono } from "../../../../styles/typography-act-2";
import { useZoom } from "./ZoomState";
import { motion } from "framer-motion";
import { fluid } from "../../../../styles/functions";
import { fontSize } from "../../../../styles/mixins";
import ArrowSvg from "../../ArrowSvg";
const Wrapper = styled(motion.div)`
	grid-column: 3/ -3;
	justify-self: center;
	@media screen and (min-width: 1250px) {
		grid-column: 31/ 42;
		justify-self: start;
	}
	width: 330px;
`;
const Container = styled(motion.div)`
	height: 280px;
	border: 1px solid ${({ theme: { foreground } }) => foreground};
	color: ${({ theme: { foreground } }) => foreground};
	${abcMaxiPlusMono};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	background-color: ${({ theme: { background } }) => background};
	position: relative;
	cursor: pointer;
`;
const Title = styled(motion.h3)`
	font-size: ${fluid(28, 40, 320, 1440)};
	font-weight: 400;
	line-height: 1;
	padding: 20px;
`;
const Open = styled(motion.button)`
	display: flex;
	justify-items: center;
	font-weight: 400;
	line-height: 1;
	${fontSize(-1)};
	svg {
		margin-right: 20px;
		transform: rotate(-90deg);
	}
	path {
		fill: ${({ theme: { foreground } }) => foreground};
	}
`;

const TopBar = styled.div`
	padding: 20px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${({ theme: { foreground } }) => foreground};
`;
const Meta = styled(motion.div)`
	${fontSize(-1)};
	line-height: 1;
	span {
		display: inline-block;
		&:last-child {
			margin: 0;
		}
	}
`;
const Tile = ({ section }) => {
	const ref = useRef();
	const zoomState = useZoom();

	return (
		<Wrapper>
			<Container
				style={{ borderRadius: "24px" }}
				layoutId={`container-${section.title}`}
				ref={ref}
				onClick={() => {
					zoomState.setActiveCard(section.title, section);
				}}>
				<TopBar>
					<Open layoutId={`button-${section.title}`}>
						<ArrowSvg />
						READ MORE
					</Open>
					<Meta layoutId={`rubrik-${section.title}`}>TEXT EXTRACT</Meta>
				</TopBar>

				<Title
					layoutId={`title-${section.title}`}
					dangerouslySetInnerHTML={{ __html: section.title.toUpperCase() }}
				/>
			</Container>
		</Wrapper>
	);
};

export default Tile;
