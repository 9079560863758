import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import keyBy from "lodash/keyBy";
import {
	Tooltip,
	XYChart,
	lightTheme,
	Axis,
	Grid,
	LineSeries,
} from "@visx/xychart";
import { container, spacing, screen, fontSize } from "../../../styles/mixins";
import { fontSurt, graphTitle } from "../../../styles/typography-act-2";
import { curveNatural } from "@visx/curve";
import { useGetResizer } from "../../../contexts/Resizer";
import { useTheme } from "@emotion/react";

const Container = styled.div`
	${spacing("marginTop", "xxl")};
	${spacing("marginBottom", "xxl")};
	${container};
	position: relative;
	z-index: 3;
	.visx-axis-tick {
		text {
			font-size: 12px;
			font-weight: 400;
			fill: ${({ theme: { foreground } }) => foreground};
			${fontSurt};
		}
	}
	.visx-axis-label {
		font-size: 12px;
		font-weight: 600;
		fill: ${({ theme: { foreground } }) => foreground};
		${fontSurt};
	}
	path {
		transition: opacity 0.3s ease-in-out;
	}
	.visx-rows {
		line:first-child {
			opacity: 0.8 !important;
			stroke-dasharray: 5;
		}
	}
`;

const Inner = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.background}D7;
	border-radius: 20px;
	border: 1px solid ${({ theme }) => theme.foreground}28;
	${spacing("padding", "s")};
	grid-column: 3 / -3;

	@media ${screen("md")} {
		grid-column: 4 / -4;
	}

	@media ${screen("ml")} {
		grid-column: 9 / -9;
	}

	@media ${screen("mx")} {
		grid-column: 15 / -15;
	}
`;
const GraphContainer = styled.div`
	width: 100%;
`;
const H3 = styled.h3`
	${graphTitle};
	${spacing("marginBottom", "m")};
`;
const Subtitle = styled.div`
	grid-column: 3 / -3;
	${fontSize(0)};
	${spacing("marginBottom", "m")};

	@media ${screen("md")} {
		grid-column: 4 / -4;
	}

	@media ${screen("ml")} {
		grid-column: 10 / span 17;
	}

	@media ${screen("mx")} {
		grid-column: 16 / span 15;
	}
`;
const ColoredSquare = styled.div`
	display: inline-block;
	width: 11px;
	height: 11px;
	margin-right: 8px;
	background: ${({ color }) => color};
	border-radius: 4px;
`;
const TooltipContainer = styled.div`
	padding: 8px 16px;
	font-size: 12px;
	border-radius: 4px;
	color: #222222;
	.value {
		display: flex;
		align-items: center;
		font-weight: 400;
		color: #000000;
		margin-bottom: 5px;
	}
`;

const TooltipDate = styled.div`
	${spacing("marginBottom", "xs")};
`;
const Legend = styled.div`
	display: flex;
	flex-wrap: wrap;
	${spacing("marginBottom", "s")};
`;
const LegendItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin: 0 20px 0 1px;
	cursor: default;
	${fontSize(-1)};
	${fontSurt};
	font-weight: bold;

	&:last-child {
		margin-right: 0;
	}

	@media ${screen("md")} {
		flex-direction: row;
	}

	&:before {
		content: " ";
		display: block;
		border-radius: 50%;
		margin-bottom: 5px;
		width: 9px;
		height: 9px;
		background-color: ${({ color }) => color};

		@media ${screen("md")} {
			margin-bottom: 0;
			margin-right: 10px;
		}
	}
`;

const Attribution = styled.p`
	${fontSurt};
	font-size: 10px;
	opacity: 0.6;
	grid-column: 5 / -3;
	text-align: center;
	${spacing("marginTop", "s")};

	a {
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);
	}

	@media ${screen("md")} {
		grid-column: 6 / -4;
	}

	@media ${screen("ml")} {
		grid-column: 11 / -10;
	}

	@media ${screen("mx")} {
		grid-column: 17 / -16;
	}
`;

const tickLabelOffset = 10;

const xAccessor = (d) => parseInt(d.Date);

export default function Graph({
	data: { childrenCostOfEducationCsv: data },
	title,
	subtitle,
	graphRows,
}) {
	const [highlightedLine, setHighlightedLine] = useState(null);
	let dataMap = keyBy(graphRows, (o) => o.key);

	const [numTicks, setNumTicks] = useState(5);
	const area = useGetResizer();
	const theme = useTheme();
	const customTheme = {
		...lightTheme,
		// order should match XYChart child series render order
		colors: graphRows.map((d) => d.color),
	};
	useEffect(() => {
		setNumTicks(Math.min(Math.floor(window.innerWidth / 70), 10));
	}, [area]);

	return (
		<Container>
			<Inner>
				<H3 dangerouslySetInnerHTML={{ __html: title }} />
				<Subtitle>{subtitle.toUpperCase()}</Subtitle>
				<GraphContainer>
					<Legend>
						{graphRows.map(({ name, color, key }) => {
							return (
								<LegendItem
									key={key}
									color={color}
									onMouseOver={() => {
										setHighlightedLine(key);
									}}
									onMouseOut={() => {
										setHighlightedLine(null);
									}}>
									{name.toUpperCase()}
								</LegendItem>
							);
						})}
					</Legend>
					<XYChart
						theme={customTheme}
						height={650}
						margin={{ left: 75, top: 35, bottom: 75, right: 27 }}
						xScale={{ type: "point" }}
						yScale={{ type: "linear", domain: [-25, 75] }}>
						<Grid
							columns={false}
							rows={true}
							tickValues={[0, 25, 50, 75]}
							lineStyle={{
								stroke: theme.foreground,
								strokeLinecap: "round",
								strokeWidth: 1,
								opacity: 0.3,
							}}
						/>
						<Axis
							hideTicks
							orientation="bottom"
							tickLabelProps={() => ({ dy: tickLabelOffset })}
							numTicks={numTicks}
							label={"YEARS"}
							labelOffset={45}
							stroke={theme.foreground}
							strokeWidth={1}
						/>
						<Axis
							tickFormat={(v) => `${v}%`}
							orientation="left"
							label={"PERCENTAGE GROWTH (%)"}
							labelOffset={40}
							stroke={theme.foreground}
							strokeWidth={1}
							tickValues={[-25, 0, 25, 50, 75]}
							tickLabelProps={() => ({ dx: -10 })}
						/>
						{graphRows.map(({ color, key }) => {
							return (
								<LineSeries
									key={key}
									stroke={color}
									opacity={
										highlightedLine !== null && highlightedLine !== key
											? 0.3
											: 1
									}
									dataKey={key}
									data={data}
									yAccessor={(d) => {
										return parseInt(d[key].replace("%", ""));
									}}
									xAccessor={xAccessor}
								/>
							);
						})}

						<Tooltip
							snapTooltipToDatumX
							snapTooltipToDatumY
							showSeriesGlyphs
							renderTooltip={({ tooltipData }) => {
								return (
									<TooltipContainer>
										<TooltipDate>
											{tooltipData.nearestDatum.datum.x}
										</TooltipDate>
										{Object.entries(tooltipData.datumByKey).map(
											(lineDataArray) => {
												const [key, value] = lineDataArray;

												return (
													<div className="row" key={key}>
														<div className="value">
															<ColoredSquare color={dataMap[key].color} />
															{value.datum[key]}
														</div>
													</div>
												);
											}
										)}
									</TooltipContainer>
								);
							}}
						/>
					</XYChart>
				</GraphContainer>
				<Attribution>
					<a
						href={
							"https://www.npr.org/2020/12/16/941292021/paycheck-to-paycheck-nation-how-life-in-america-adds-up?t=1620834018070"
						}
						target={"_blank"}
						rel={"noreferrer"}>
						NPR
					</a>
					,{" "}
					<a
						href={
							"https://nces.ed.gov/programs/digest/d19/tables/dt19_330.10.asp?current=yes"
						}
						target={"_blank"}
						rel={"noreferrer"}>
						National Center for Education Statistics
					</a>
					,{" "}
					<a
						href={
							"https://www.cms.gov/Research-Statistics-Data-and-Systems/Statistics-Trends-and-Reports/NationalHealthExpendData/NationalHealthAccountsHistorical"
						}
						target={"_blank"}
						rel={"noreferrer"}>
						Centers For Medicaid and Medicare Services
					</a>
					,{" "}
					<a
						href={
							"https://www.census.gov/construction/nrs/historical_data/index.html"
						}
						target={"_blank"}
						rel={"noreferrer"}>
						US Census
					</a>
					,{" "}
					<a
						href={"https://www.ssa.gov/oact/cola/central.html"}
						target={"_blank"}
						rel={"noreferrer"}>
						Social Security Administration
					</a>
				</Attribution>
			</Inner>
		</Container>
	);
}
