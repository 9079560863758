import React, { useCallback, useEffect, useRef, useState } from "react";
import importDirectory from "../../../util/importDirectory";
import { usePageLayout } from "../../../contexts/PageLayout";
import { useSectionLayout } from "../../../util/useSectionLayout";
import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import { defaultTheme } from "../themes";
import { useUIState } from "../../../contexts/UIStore";
let features = importDirectory(
	require.context("../", true, /Feature\/[a-zA-Z0-9_-]*\.js$/),
	"default"
);
let structure = importDirectory(
	require.context("../", true, /Structure\/[a-zA-Z0-9_-]*\.js$/),
	"default"
);
const Container = styled.div`
	//padding: 10px 0;
`;
let components = { ...features, ...structure };
const Standard = ({ data, chapterKey, index, chapter }) => {
	const localRef = useRef();
	const uiState = useUIState();
	const cbRef = useSectionLayout({ data, chapterKey, index });
	const theme = {};
	if (data.theme) {
		if (data.theme.foreground) {
			theme.foreground = data.theme.foreground;
		}
		if (data.theme.background) {
			theme.background = data.theme.background;
		}
		if (data.theme.alignment) {
			theme.alignment = data.theme.alignment;
		}
	}
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						uiState.addTheme({ ...theme }, `${chapterKey}_${index}`, 2);
					} else {
						uiState.removeTheme(`${chapterKey}_${index}`);
					}
				});
			},
			{ rootMargin: "-100% 0px 0px 0px" }
		);
		observer.observe(localRef.current);
		return () => {
			observer.disconnect();
		};
	}, [uiState, data]);

	let combinedRef = useCallback(
		(node) => {
			cbRef(node);
			localRef.current = node;
		},
		[cbRef, localRef]
	);

	return (
		<ThemeProvider theme={theme}>
			<Container ref={combinedRef}>
				{data.body.map((block, i) => {
					let Component = components[block.component];
					if (Component) {
						return <Component {...block} key={i} chapter={chapter} />;
					} else {
						return <div key={i}>Missing Component: {block.component}</div>;
					}
				})}
			</Container>
		</ThemeProvider>
	);
};

export default Standard;
