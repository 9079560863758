import React from "react";
import styled from "@emotion/styled";
import { container, spacing, screen } from "../../../styles/mixins";
import { richText } from "../../../styles/typography-act-2";
import { css, useTheme } from "@emotion/react";

const Container = styled.div`
	${({ theme: { alignment, columns } }) =>
		container({ cols: columns ? columns : alignment === "modal" ? 36 : 48 })};
	${({ theme: { alignment } }) =>
		spacing(
			"marginTop",
			alignment === "modal" || alignment === "largeModal" ? "xl" : "xl"
		)};
	${({ theme: { alignment } }) =>
		spacing(
			"marginBottom",
			alignment === "modal" || alignment === "largeModal" ? "xl" : "xl"
		)};
	z-index: 3;
	position: relative;
`;

/*
mx: 14  (18 / 32)
ml: 22 (14 / 36)
md: 32 (9/41)
 */

export const positions = {
	center: css`
		grid-column: 3 / -3;

		@media ${screen("md")} {
			grid-column: 9 / span 32;
		}

		@media ${screen("ml")} {
			grid-column: 14 / span 22;
		}

		@media ${screen("mx")} {
			grid-column: 18 / span 14;
		}
	`,
	left: css`
		grid-column: 3 / -3;

		@media ${screen("md")} {
			grid-column: 7 / span 32;
		}

		@media ${screen("ml")} {
			grid-column: 7 / span 22;
		}

		@media ${screen("mx")} {
			grid-column: 7 / span 14;
		}
	`,
	right: css`
		grid-column: 3 / -3;

		@media ${screen("md")} {
			grid-column: span 32 / -7;
		}

		@media ${screen("ml")} {
			grid-column: span 22 / -7;
		}

		@media ${screen("mx")} {
			grid-column: span 14 / -7;
		}
	`,
	modal: css`
		grid-column: 3 / -3;

		@media ${screen("md")} {
			grid-column: 25 / span 21;
		}

		@media ${screen("ml")} {
			grid-column: 25 / span 21;
		}

		@media ${screen("mx")} {
			grid-column: 25 / span 21;
		}
	`,
	largeModal: css`
		grid-column: 3 / -3;

		@media ${screen("md")} {
			grid-column: 25 / span 21;
		}

		@media ${screen("ml")} {
			grid-column: 25 / span 21;
		}

		@media ${screen("mx")} {
			grid-column: 25 / span 21;
		}
	`,
};
const Content = styled.div`
	${({ theme: { alignment } }) => positions[alignment]};
	color: ${({ theme: { foreground } }) => foreground};
	text-align: justify;
	hyphens: auto;
	font-size: 20px;
	line-height: 1.6;
	${({ theme }) => {
		return theme.alignment !== "modal" && theme.alignment !== "largeModal"
			? css`
					background: ${theme.background}D7;
					${spacing("padding", "s")};
					border-radius: 20px;
					border: 1px solid ${theme.foreground}28;
			  `
			: null;
	}};
	//backdrop-filter: blur(2px);
	will-change: transform;
	--link-underline: ${({ theme }) => theme.foreground}AC;
	--link-underline-hover: ${({ theme }) => theme.foreground}FF;
	${richText};
`;
const BodyCopy = ({ content }) => {
	return (
		<Container>
			<Content dangerouslySetInnerHTML={{ __html: content }} />
		</Container>
	);
};

export default BodyCopy;
