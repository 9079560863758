import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { abcMaxiPlusMono } from "../../../styles/typography-act-2";
import { fontSize, spacing } from "../../../styles/mixins";
import Button from "../../UI/Button";
const Container = styled.div`
	color: ${({ theme }) => theme.foreground};
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 3;
	position: relative;
	${spacing("marginTop", "xxl")};
	${spacing("marginBottom", "xxl")};
	font-feature-settings: "salt" on;
`;
const Title = styled.h3`
	${abcMaxiPlusMono};
	${fontSize(6)};
	text-align: center;
	line-height: 1.17;
	${spacing("marginTop", "l")};
	${spacing("marginBottom", "l")};
`;
const Meta = styled.div`
	${abcMaxiPlusMono};
	font-size: 12px;
`;
const Next = styled.div``;
const Outro = ({ meta, title, link }) => {
	return (
		<Container>
			<Meta>{meta}</Meta>
			<Title dangerouslySetInnerHTML={{ __html: title.toUpperCase() }} />
			{link ? (
				<Button theme={"dark"} to={link}>
					READ NOW
				</Button>
			) : (
				<Next>
					COMING SOON <strong>Q1 2022</strong>
				</Next>
			)}
		</Container>
	);
};

export default Outro;
