import TimelineContainer from "./NewDigitalEconomies/TimelineContainer";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ZoomProvider } from "./NewDigitalEconomies/ZoomState";
import { LayoutGroup } from "framer-motion";
import { ThemeProvider } from "@emotion/react";
const NewDigitalEconomies = () => {
	const {
		crypto: { nodes: crypto },
		gig: { nodes: gig },
		virtual: { nodes: virtual },
		influence: { nodes: influence },
		sharing: { nodes: sharing },
	} = useStaticQuery(graphql`
		query NewDigitalEconomies {
			crypto: allNewEconomyCompany(
				sort: { fields: boom, order: ASC }
				filter: { economy: { eq: "CRYPTO" } }
			) {
				nodes {
					id
					name
					boom
					economy
					description
					mainActivity
					socialImpact
				}
			}
			gig: allNewEconomyCompany(
				sort: { fields: boom, order: ASC }
				filter: { economy: { eq: "GIG" } }
			) {
				nodes {
					id
					name
					boom
					economy
					description
					mainActivity
					socialImpact
				}
			}
			influence: allNewEconomyCompany(
				sort: { fields: boom, order: ASC }
				filter: { economy: { eq: "INFLUENCE" } }
			) {
				nodes {
					id
					name
					boom
					economy
					description
					mainActivity
					socialImpact
				}
			}
			virtual: allNewEconomyCompany(
				sort: { fields: boom, order: ASC }
				filter: { economy: { eq: "VIRTUAL" } }
			) {
				nodes {
					id
					name
					boom
					economy
					description
					mainActivity
					socialImpact
				}
			}
			sharing: allNewEconomyCompany(
				sort: { fields: boom, order: ASC }
				filter: { economy: { eq: "SHARING" } }
			) {
				nodes {
					id
					name
					boom
					economy
					description
					mainActivity
					socialImpact
				}
			}
		}
	`);

	return (
		<ZoomProvider>
			<ThemeProvider theme={{ alignment: "modal" }}>
				<LayoutGroup>
					<TimelineContainer
						categories={[
							{ title: "Crypto", companies: crypto },
							{ title: "Influence", companies: influence },
							{ title: "Gig", companies: gig },
							{ title: "Sharing", companies: sharing },
							{ title: "Virtual", companies: virtual },
						]}
					/>
				</LayoutGroup>
			</ThemeProvider>
		</ZoomProvider>
	);
};
export default NewDigitalEconomies;
