import { useCallback, useEffect, useRef } from "react";
import { usePageLayout } from "../contexts/PageLayout";
import { useGetResizer } from "../contexts/Resizer";

export function useSpecialLayout({ theme, id }) {
	let ref = useRef();

	const pageLayout = usePageLayout();
	const area = useGetResizer();

	useEffect(() => {
		if (ref.current) {
			const rect = ref.current.getBoundingClientRect();
			const scrollY = window.scrollY;
			let section = {
				start: rect.top + scrollY,
				end: rect.bottom + scrollY,
				theme: theme,
				key: id,
			};
			pageLayout.addSpecialSection(id, section);
		}
	}, [area, pageLayout, id, theme]);
	return useCallback(
		(node) => {
			if (node && node !== ref.current) {
				ref.current = node;
				const rect = ref.current.getBoundingClientRect();
				const scrollY = window.scrollY;
				let section = {
					start: rect.top + scrollY,
					end: rect.bottom + scrollY,
					theme: theme,
					key: id,
				};
				pageLayout.addSpecialSection(id, section);
			}
		},
		[theme, id, pageLayout]
	);
}
