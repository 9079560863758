import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Inventions from "./Inventions";
import { useNav } from "./NavContext";
import { useSpecialLayout } from "../../../../util/useSpecialLayout";
import { ThemeProvider } from "@emotion/react";
import { spacing, screen } from "../../../../styles/mixins";
import { useUIState } from "../../../../contexts/UIStore";
import Introduction from "./Introduction";
import { useGetResizer } from "../../../../contexts/Resizer";

const Container = styled.div`
	position: relative;
	${({ theme }) => ({
		backgroundColor: theme.background,
	})};
	${spacing("paddingBottom", "xxxl")};
	display: flex;
	flex-direction: column;
	@media ${screen("ml")} {
		flex-direction: row;
	}
`;

const Revolution = ({
	revolution,
	content,
	shortTitle,
	index,
	total,
	theme,
}) => {
	const ref = useRef();
	const nav = useNav();
	const [position, setPosition] = useState({
		start: 0,
		end: 0,
		winHeight: 1000,
		winWidth: 1440,
	});
	const area = useGetResizer();

	const uiState = useUIState();

	const cbRef = useSpecialLayout({ theme, id: index });
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						nav.setRevolution(revolution.id);
						nav.setTheme(theme);
						uiState.addTheme(theme, revolution.id, 3);
					} else {
						uiState.removeTheme(revolution.id);
					}
				});
			},
			{ rootMargin: "0px 0px -100% 0px" }
		);
		observer.observe(ref.current);
		return () => {
			observer.disconnect();
		};
	}, [nav, theme, uiState, revolution.id]);

	useEffect(() => {
		const rect = ref.current.getBoundingClientRect();
		setPosition({
			start: rect.top + window.scrollY,
			end: rect.top + window.scrollY + window.innerHeight,
			winHeight: window.innerHeight,
			winWidth: window.innerWidth,
		});
	}, [area]);

	const combinedRef = useCallback(
		(node) => {
			cbRef(node);
			ref.current = node;
		},
		[ref, cbRef]
	);
	return (
		<ThemeProvider theme={theme}>
			<Container ref={combinedRef} id={`revolution-${shortTitle}`}>
				<Introduction
					content={content}
					shortTitle={shortTitle}
					index={index}
					position={position}
					total={revolution.inventions.length}
					start={revolution.startDate}
					end={revolution.endDate}
				/>

				<Inventions
					revolution={revolution}
					shortTitle={shortTitle}
					total={total}
					index={index}
				/>
			</Container>
		</ThemeProvider>
	);
};

export default Revolution;
